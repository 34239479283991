import React, { useState, useEffect, useRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import Card from "react-bootstrap/Card";
import moment from "moment";
import MatDatagrid from "f1-mat-data-grid-react";
import "./commercialTimesheet.scss";
import TextField from "@material-ui/core/TextField";

import {
  BASE_URL,
  CUSTOM_EMAIL_NOTIFICATION,
  ADD_TIMESHEET_DETAILS,
  GRAMERLY_CLIENT_ID, GRAMERLY_ASSERTION_ID,
  UPDATE_PENDINGTASKS,
  INSERT_PENDING_TIMESHEET_DETAILS,
  isMask,
  INSERT_CASE_FILE,
  HTML_TO_PDF,
  UPDATE_CMSTIMESHEET,
  isSyncBack,
  mailTimesheet,mailMask,COMMERCIAL_REVIEW_EMAIL_SUBJECT,COMMERCIAL_EMAIL_SUBJECT,
  CMSSYNC_LOGICAPP
} from "../../api/baseURL";
import namlogo from "../../assets/img/namlogo.png";
import Button from "react-bootstrap/Button";
import axios from "axios";
import DatePicker from "react-date-picker";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import * as Utility from "../../utils/utility";
import ReviewTimesheetDialog from "../../shared/ReviewTimesheetDialog";
import CommercialTimesheetTemplate from "./commercialTimesheetTemplate";
import { decryptItem, encryptItem } from "../../shared/Encrypt";
import F1GrammarCheckerTextArea from 'f1-grammar-enabled-textarea-react';

document.querySelectorAll("grammarly-editor-plugin").forEach((grammarlyEditor) => {
  grammarlyEditor.config = {
    oauthAssertionProvider: async () => {
      const response = await fetch(GRAMERLY_ASSERTION_ID, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clientId: GRAMERLY_CLIENT_ID,
        }),
      });
      if (!response.ok) {
        throw new Error("Error creating assertion");
      }
      return await response.json();
    },
  };
});
const CommercialTimesheet = (props) => {  
  const [timeSheetIndex,setTimesheetIndex]=useState(props.index);
  console.log("timeSheetIndex,timeSheetIndex",timeSheetIndex);
  const [reviewNotes, setreviewNotes] = useState(false);
  const [isDisabled, setIsDisable] = useState(false);
  const [isZeroHour, setisZeroHour] = useState(false);
  const [timesheetArr, settimesheetArr] = useState(
    props.timesheetDetails.commercialtimesheetDetails
  );
  const monthNyear =props.timesheetDetails.timesheetName.split(" ");//0th elemnt month an 1th element year

  const [timesheetDetails, settimesheetDetails] = useState(
    props.timesheetDetails
  );
  const [isCancel, setisCancel] = useState(props.cancel);
  const [reviewStatus, setReviewStatus] = useState("");
  const [isdisplayAlert, setdisplayAlert] = useState(false);
  const [reviewValidation, setreviewvalidation] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDescription,setIsDescription]=useState(false);
  const [avoidDuplicates,setAvoidDuplicates]=useState(false);
  const [isrowCancel, setisrowCancel] = useState(false);
  const [textData,setTextData] = useState(props.timesheetDetails?.additionalNotes || "");
  const [textData1,setTextData1] = useState(props.timesheetDetails?.additionalNotes || "");
  const [reviewTextData,reviewSetTextData] = useState(props.timesheetDetails?.reviewNotes || "");
  const [reviewTextData1,reviewSetTextData1] = useState(props.timesheetDetails?.reviewNotes || "");
  let addZeroHourTimeSheet=false;
  const totalRecords =
    props.timesheetDetails.commercialtimesheetDetails.length
    ;
    useEffect(() => {
      //console.log("props : ", props);
      var element = document.getElementById("submitReview");
      if(element!=undefined) 
      {element.classList.remove("btn");
      
    }
    },[]);

  //Grammar Checking Stuff V V ################################################################
  useEffect(()=>{
        
    // setAdditionalNotes(textData);
    //   setisadditionaNotesError(false);
    settimesheetDetails({...timesheetDetails,additionalNotes:textData});
     // setPMADetails({ ...pmaDetails, decision: textData });
     

  },[textData])

  useEffect(()=>{
    settimesheetDetails({
      ...timesheetDetails,
      reviewNotes: reviewTextData,
    });
    setreviewNotes(false);
    if (reviewStatus === "Clarification Required") {
        if(reviewTextData === "" || reviewTextData === null){
          setreviewNotes(true);
        }
    }
  },[reviewTextData]
  )
  // // useEffect(()=>{ //This will emulate the disbaled and enabled function of textarea
  // //   if (isDisabled){

  // //       let elChild = document.getElementsByClassName('additionanotestimesheetclass')[1]
  // //       elChild.style.setProperty('text-align', 'left', 'important');
  // //       elChild.setAttribute('contentEditable', "false")
  // //       elChild.style.cursor = "not-allowed" //Just for visual elements
  // //       //console.log(el)
  // //   }
  // //   else{
  // //       let elChild = document.getElementsByClassName('additionanotestimesheetclass')[1]
  // //       elChild.style.setProperty('text-align', 'left', 'important');
  // //       elChild.setAttribute('contentEditable', "true")
  // //       //console.log(el)
  // //   }
  // // }, [isDisabled])
  // // useEffect(() => { //Attaching event listener to emulate onchange behavior
  // //     let elChild = document.getElementsByClassName('additionanotestimesheetclass')[1];

  // //     const handleInput = () => {
  // //           settimesheetDetails({
  // //             ...timesheetDetails,
  // //             additionalNotes: elChild.innerText,
  // //           });
  // //     };
  // //     if (elChild) {
  // //         elChild.addEventListener('input', handleInput);
  // //     }
  // //     return () => {
  // //         if (elChild) {
  // //             elChild.removeEventListener('input', handleInput);
  // //         }
  // //     };
  // // }, [timesheetDetails, settimesheetDetails]);
//############################################################################################

  const dataURLtoFile = (dataurl, filename) => {
    try {
      const arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);

      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    } catch (ex) { }
  };
  const addHours = (hours, data, oldValue) => {
    const hrs = parseInt(hours) * 60;
    // if (!oldValue)
    //   oldValue = hours - 1;
    const oldHrs =
      oldValue !== null && oldValue !== "" ? parseInt(oldValue) * 60 : 0;
    console.log("add hours", hours, oldValue, data);
    return data !== null && data !== "" && !isNaN(data)? data - oldHrs + hrs : hrs;
  };
  const addMinutes = (minutes, data, oldValue) => {
    const min = parseInt(minutes);
    if (!oldValue)
      oldValue = minutes - 1;
    const oldHrs =
      oldValue !== null && oldValue !== "" ? parseInt(oldValue) : 0;
    console.log("add minutes", minutes, oldValue, data);
    return data !== null && data !== "" && !isNaN(data) ? data - oldHrs + min : min;
  };
  const getDecimalTime = (data) => {
    console.log(
      "getDecimalTime",
      data,
      isNaN(data / 60),
      isNaN(data / 60) ? data : (data / 60).toFixed(2)
    );
    return data !== null && data !== "" && data !== undefined
      ? isNaN((data / 60).toFixed(2))
        ? data
        : (data / 60).toFixed(2)
      : "";
  };
  const getMinutes = (data) => {
    return data !== null && data !== "" && data !== undefined
      ? isNaN(data % 60)
        ? data.hearingDuration
        : Math.round(data % 60)
      : "";
  };

  //preventing enter key on add entry
  const onKeyDownPreventEnterClick=(e)=>{
    if(e.key === "Enter")
    {
      e.stopPropagation();
    }
  }

  const getHours = (data) => {
    return data !== null && data !== "" && data !== undefined
      ? isNaN(Math.floor(data / 60))
        ? data.hearingDuration
        : Math.floor(data / 60)
      : "";
  };
  const myRefHours = useRef(null);
  const myRefMinutes = useRef(null);
  const myRef = useRef(null);
const timesheetDetailsStatus=(timesheetDetails.status === "Sent for Review" && !Utility.IsHearingOfficer()?"": " ")

  //setisCancel(props.cancel);
  const columns = [
    {
      title: "Line Item"+timesheetDetailsStatus,
      field: "lineItem",
      sorting: false,
      className:"required-field",
      render: (rowData) => (
        <div style={{ width: "250px", display: "flex", alignItems: "center",cursor:"pointer" }}>
          {rowData.lineItem !== null && rowData.lineItem !== undefined && rowData.lineItem !== ""
            ? 
            (rowData.lineItem.replace("^", ", ").split(",")[1].trim()?
             rowData.lineItem.replace("^", ", ") : rowData.lineItem.replace("^", ", ").split(",")[0])
            : ""}

          {/* dd  {(rowData.lineItem.replace("^", ", ")).split(",")[0]} */}
        </div>
      ),
      validate: (row) => !
      (row.lineItem === "" ||
      row.lineItem === null ||
      row.lineItem === undefined 
      || DiscriptionRequired(row.lineItem,true)
      // ||
      // row.lineItem.replace('^',',').split(",")[0] === "" ||
      // row.lineItem.replace('^',',').split(",")[1] === "" ||
      // row.lineItem.replace('^',',').split(",").length < 2
    )  ,
      editComponent: (props1) => {
        var element = document.getElementById("submitReviewTimesheet");
        if(element!=undefined)
        {
          element.classList.add('pmaButtonDisabled');
          element.disabled=true;
         
        }
        return (
          <>
            <div style={{ position: "relative" }}>
              {/* <span className="alertMsg">*</span> */}
              <select
              onKeyDown={onKeyDownPreventEnterClick}
                className="form-control"
                value={
                  props1.rowData.lineItem !== null &&
                    props1.rowData.lineItem !== undefined &&
                    props1.rowData.lineItem !== ""
                    ? props1.rowData.lineItem.substring(
                      0,
                      props1.rowData.lineItem.replace(/,/, '^').indexOf("^")
                    )
                    : ""
                }
                onChange={(e) => {
                  props1.onChange(e.target.value);
                  let data = { ...props1.rowData };
                  var item =
                    data.lineItem !== null && data.lineItem !== undefined && data.lineItem !== ""
                      ? data.lineItem.substring(
                        0,
                        data.lineItem.indexOf("^") + 1
                      )
                      : "";
                  data.lineItem =
                    data.lineItem !== null && data.lineItem !== undefined && data.lineItem !== ""
                      ? data.lineItem.trim().replace(item, `${e.target.value}^`)
                      : `${e.target.value}^`;
                  props1.onRowDataChange(data);

                }}
              >
                <option value="">SELECT</option>
                <option value="Correspondence">Correspondence</option>
                <option value="Document Review">Document Review</option>
                <option value="Email">Email</option>
                <option value="Final Decision prep">Final Decision prep</option>
                <option value="In-Person Hearing">In-Person Hearing</option>
                <option value="Interim order/decision prep">Interim order/decision prep</option>
                <option value="Motion practice">Motion practice</option>
                <option value="Other">Other</option>
                <option value="Paper Arbitration">Paper Arbitration</option>
                <option value="Phone-Video Conference">Phone-Video Conference</option>
                <option value="Preliminary telephone conference">Preliminary telephone conference</option>
                <option value="Telephone conference">Telephone conference</option> 
              </select>

              <TextField
                onKeyDown={onKeyDownPreventEnterClick}          
                className="form-control"
                required={DiscriptionRequired(props1.rowData.lineItem)}
                placeholder={
                  props1?.rowData?.lineItem && props1.rowData.lineItem.split("^")[0] === "Other" ? "Description Required" : "Description"
                }
                inputProps={{ maxlength: 100, minlegth: 2 }}
                value={
                  props1.rowData.lineItem !== null &&
                    props1.rowData.lineItem !== undefined &&
                    props1.rowData.lineItem !== ""
                    ? props1.rowData.lineItem.replace(/,/, '^').indexOf("^") > -1
                      ? props1.rowData.lineItem.substring(
                        props1.rowData.lineItem.replace(/,/, '^').indexOf("^") + 1
                      )
                      : props1.rowData.lineItem
                    : ""
                }
                onChange={(e) => {
                  props1.onChange(e.target.value);
                  let data = { ...props1.rowData };
                  data.lineItem = `${data.lineItem !== null && data.lineItem !== undefined && data.lineItem !== ""
                    ? data.lineItem.indexOf("^") > -1
                      ? data.lineItem
                        .trim()
                        .substring(0, data.lineItem.indexOf("^") + 1)
                      : (data.lineItem.indexOf(",") > -1
                      ? data.lineItem
                        .trim()
                        .substring(0, data.lineItem.indexOf(",") + 1)
                      : "")
                    : ""
                    }${e.target.value}`;
                  props1.onRowDataChange(data);

                }}
              />
                </div>
              {/* {props1.rowData.lineItem === "" ||
                props1.rowData.lineItem === null ||
                props1.rowData.lineItem === undefined ||
                props1.rowData.lineItem.replace('^',',').split(",")[0] === "" ||
                props1.rowData.lineItem.replace('^',',').split(",")[1] === "" ||
                props1.rowData.lineItem.replace('^',',').split(",").length < 2 ? (
                <span style={{ color: "red" }} className="custom--required">
                  
                  Required
                 
                </span>
              ) : (
                <span></span>
              )} */}
          
          </>
        );
      },
    },
    {
      title:"Date"+timesheetDetailsStatus,
      field: "entryDate",
      sorting: false,
      width: "175px",
      render: (rowData) => (
        <div style={{width: "195px", display: "flex", alignItems: "center" }}>
          {rowData.entryDate !== null && rowData.entryDate !== ""
            ? moment(rowData.entryDate).format("DD-MMM-yyyy")
            : ""}
        </div>
      ),
      validate: (row) =>
        (row.entryDate !== null && row.entryDate !== undefined && row.entryDate !== "" && row.entryDate
          !=="Invalid Date"
          ? moment(row.entryDate).format("DD-MMM-yyyy")
          : ""
        ).length !== 0 ,
      editComponent: (props2) => {
        return (
          <><div style={{ width: "192px", position: "relative" }}>
            {props2.rowData.entryDate !== null && props2.rowData.entryDate !== ""}
            {/* <span className="alertMsg">*</span> */}
            <DatePicker
            locale="en-US"
              // onKeyDown={(e) => {
              //   e.preventDefault();
              // }}    
              onKeyDown={onKeyDownPreventEnterClick}          
              width="150px"
              format="dd/MMM/yyyy"
              minDate={Utility.IsHearingOfficer() && new Date(parseInt(monthNyear[1]), Utility.monthNames[monthNyear[0].trim().toLowerCase()], 1)}
              maxDate={ Utility.IsHearingOfficer() && new Date(parseInt(monthNyear[1]),  Utility.monthNames[monthNyear[0].trim().toLowerCase()], 
                new Date(parseInt(monthNyear[1]),  Utility.monthNames[monthNyear[0].trim().toLowerCase()] + 1, 0, 23, 59, 59).getDate())
              }
              value={
                props2.rowData.entryDate !== null &&
                  props2.rowData.entryDate !== undefined &&
                  props2.rowData.entryDate !== "" && props2.rowData.entryDate
                  !=="Invalid Date"
                  ? new Date(props2.rowData.entryDate)
                  : ""
              }
              onChange={(event) => {
                let e = event;
                props2.onChange(e);
                let data = { ...props2.rowData };
                data.entryDate = e;
                props2.onRowDataChange(data);
              }}
            />
            </div>
            {/* {props2.rowData.entryDate === "" ||
              props2.rowData.entryDate === null ||
              props2.rowData.entryDate === undefined ? (
              <span
                style={{
                  color: "red"
                  
                }}
                className="custom--required"
              >
                
                Required
               
              </span>
            ) : (
              <span></span>
            )} */}
          </>
        );
      },
    },
    {
      title: "Start Time",
      field: "startTime",
      width: "120px",
      sorting: false,
      render: (rowData) => (
        <div
          className="response-submission"
          style={{ display: "flex", alignItems: "center" }}
        >
          {rowData.startTime !== "" &&
            rowData.startTime !== null &&
            rowData.startTime !== undefined
            ? new Date(rowData.startTime).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
            : ""}
        </div>
      ),
      editComponent: (props3) => {
        return (
          <div>
          <div style={{ width: "110px" }}>
            <TimePicker
              onKeyDown={onKeyDownPreventEnterClick}
              format="hh:mm a"
              disableClock="true"
              onChange={(e) => {
                console.log('in change');
                const dt1 =
                  props3.rowData.entryDate !== null &&
                    props3.rowData.entryDate !== "" &&
                    props3.rowData.entryDate !== undefined
                    ? new Date(props3.rowData.entryDate)
                    : new Date();
                if (e) {
                  dt1.setHours(e["$H"]);
                  dt1.setMinutes(e["$m"]);
                }
                else {
                  let data = { ...props3.rowData };
                  data.startTime = "";
                  data.hearingDuration="";
                  props3.onRowDataChange(data);
                  return;
                }
                if(props3.rowData.endTime && props3.rowData.endTime>dt1)
                {
                  props3.rowData.iserrorStartTime=false;
                      props3.rowData.errorStartTime="";
                }
                else
                { 
                  if (props3.rowData.endTime && props3.rowData.endTime < dt1) {
                    props3.rowData.iserrorStartTime = true;
                    props3.rowData.errorStartTime = "Start time should be less than end time";
                  }
                }
                
                props3.onChange(dt1);
                let data = { ...props3.rowData };
                data.startTime = dt1;
                
                data.hearingDuration =
                  data.startTime !== null &&
                    data.startTime !== undefined &&
                    data.startTime !== "" &&
                    data.endTime !== null &&
                    data.endTime !== undefined &&
                    data.endTime !== ""
                    ? Utility.diff_minutes(
                      new Date(data.startTime),
                      new Date(data.endTime)
                    )
                    : data.hearingDuration;
                props3.onRowDataChange(data);
                    
                 
              }}
              value={
                props3.rowData.startTime !== null &&
                  props3.rowData.startTime !== undefined &&
                  props3.rowData.startTime !== ""
                  ? dayjs(new Date(props3.rowData.startTime))
                  : ""
              }
            />
            </div>
             <span style={{color:'red'}}>{props3.rowData.errorStartTime}</span>
          </div>
        );
      },
    },
    {
      title: "End Time",
      field: "endTime",
      sorting: false,
      width: "110px",
      render: (rowData) => (
        <div
          className="response-submission"
          style={{ display: "flex", alignItems: "center" }}
        >
          {rowData.endTime !== "" &&
            rowData.endTime !== undefined &&
            rowData.endTime !== null
            ? new Date(rowData.endTime).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
            : ""}
        </div>
      ),
      editComponent: (props4) => {
        return (<div>
          <div style={{ width: "120px" }}>
            <TimePicker
              onKeyDown={onKeyDownPreventEnterClick} 
              format="hh:mm a"
              disableClock="true"
              onChange={(e) => {
                const dt1 =
                  props4.rowData.entryDate !== null &&
                    props4.rowData.entryDate !== "" &&
                    props4.rowData.entryDate !== undefined
                    ? new Date(props4.rowData.entryDate)
                    : new Date();
                if (e) {
                  dt1.setHours(e["$H"]);
                  dt1.setMinutes(e["$m"]);
                }
                else {
                  let data = { ...props4.rowData };
                  data.endTime = "";
                  data.hearingDuration="";
                  props4.onRowDataChange(data);
                  return;
                }
                if (props4.rowData.startTime < dt1) {
                  props4.onChange(dt1);
                  props4.rowData.iserrorStartTime = false;
                  props4.rowData.errorStartTime = "";
                  let data = { ...props4.rowData };
                  data.endTime = dt1;
                  data.hearingDuration =
                    data.startTime !== null &&
                      data.startTime !== undefined &&
                      data.startTime !== "" &&
                      data.endTime !== null &&
                      data.endTime !== undefined &&
                      data.endTime !== ""
                      ? Utility.diff_minutes(
                        new Date(data.startTime),
                        new Date(data.endTime)
                      )
                      : data.hearingDuration;

                  props4.onRowDataChange(data);
                }
                if(props4?.rowData?.startTime && props4.rowData.startTime>dt1){
                    props4.rowData.iserrorStartTime=true;
                    props4.rowData.errorStartTime="Start time should be less than end time";

                    props4.onChange(dt1);
                    let data = { ...props4.rowData };
                    data.endTime = dt1;
                }
              }}
              value={
                props4.rowData.endTime !== null &&
                  props4.rowData.endTime !== undefined &&
                  props4.rowData.endTime !== ""
                  ? dayjs(new Date(props4.rowData.endTime))
                  : ""
              }
            />
            </div>
          </div>
        );
      },
    },
    {
      title: "Net Time"+timesheetDetailsStatus,
      field: "hearingDuration",
      sorting: false,
      render: (rowData) => (
        <div
          className="response-submission"
          style={{ display: "flex", alignItems: "center" }}
        >
          {Utility.toHoursAndMinutes(rowData.hearingDuration)}
        </div>
      ),
      validate: (row) => (row.lineItem=== "Other, Zero hour timesheet" && row.hearingDuration==0)||(row.hearingDuration || "").length !== 0,
      editComponent: (props5) => {
        return (
         <> <div className="commercial-timesheet-duration" style={{ width: "240px", textAlign: "center",position:"relative" }}>
            <div style={{ textAlign: 'left' }}>
              {/* <span className="alertMsg">*</span> */}
            </div>
            <div className="text-center">

              <input
                type="hidden"
                value={getHours(props5.rowData.hearingDuration)}
                id="hours"
              />

              <TextField                 
                style={{ width: "70px", float: "left",textAlign:'inherit' }}
                required={true}
                ref={myRefHours}
                
                inputProps={{ maxlength: 5, minlength: 0 ,min:0,max:24}}
                value={getHours(
                  props5.rowData.hearingDuration
                )}
                type="number"
                onKeyDown={(event)=>{
                  if (event.key
                      ==
                      "-" || event.key === "Enter") {
                      event.preventDefault();
                      event.stopPropagation();
              } }} 
              
                min={0}
                pattern="[0-9]*"
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  const oldValue = myRefHours.current.previousSibling.value;
                  if (e.target.value !== "" && parseInt(e.target.value) > 0  && parseInt(e.target.value)<=24  && re.test(e.target.value)) {
                    props5.onChange(e.target.value);
                    if(parseInt(e.target.value)===24){
                    myRefMinutes.current.previousSibling.value = 0;
                    e.target.value=24
                    }
                    let data5 = { ...props5.rowData };
                    if(isNaN(data5.hearingDuration) ||data5.hearingDuration===undefined )
                    {data5.hearingDuration=0;}

                    if (parseInt(e.target.value) === 24) {
                      data5.hearingDuration = 1440;
                    }
                    else {
                      data5.hearingDuration = addHours(
                        e.target.value,
                        data5.hearingDuration,
                        oldValue
                      );
                    }
                    myRefHours.current.previousSibling.value = e.target.value;
                    data5.startTime = null;
                    data5.endTime = null;

                    props5.onRowDataChange(data5);
                  } else {
                    if(parseInt(e.target.value) < 0){
                      e.target.value="0"
                      return;
                    }
                    let data = { ...props5.rowData };
                    data.hearingDuration = addHours(
                      0,
                      data.hearingDuration,
                      oldValue
                    );
                    props5.onRowDataChange(data);
                  }
                }}
              />{" "}
              <span style={{ padding: "7px 5px", float: "left" }}>Hrs</span>
              <input
                type="hidden"
                value={getMinutes(props5.rowData.hearingDuration)}
                id="minutes"
              />
              <TextField
                ref={myRefMinutes}
                style={{ width: "70px", float: "left" ,textAlign:'inherit'}}
                required={true}
                inputProps={{ maxlength: 2, minlength: 0, min:0,max:59 }}
                value={getMinutes(props5.rowData.hearingDuration)}
                type="number"
                onKeyDown={(event)=>{
                  if (event.key
                      ==
                      "-" ||event.key === "Enter") {
                        event.preventDefault();
                      event.stopPropagation();                  
              } }} 
              
                min={0}
                pattern="[0-9]*"
                onChange={(e) => {
                  const oldValue = myRefMinutes.current.previousSibling.value;
                  const re =/^[0-9\b]+$/; // /^([0-5]+([0-9]*)?|[0-9]+)$/;
                  if (e.target.value !== "" && e.target.value > 0 && parseInt(e.target.value)<=59 && re.test(e.target.value)) {
                    props5.onChange(e.target.value);
                    let data = { ...props5.rowData };
                    if(getHours(
                      props5.rowData.hearingDuration
                    )===24){
                      e.target.value=0;
                    }
                    data.hearingDuration = addMinutes(
                      e.target.value,
                      data.hearingDuration,
                      oldValue
                    );
                    myRefMinutes.current.previousSibling.value = e.target.value;
                    data.startTime = null;
                    data.endTime = null;

                    props5.onRowDataChange(data);
                  } else {
                    let data1 = { ...props5.rowData };

                    data1.hearingDuration = addMinutes(
                      0,
                      data1.hearingDuration,
                      oldValue
                    );
                    props5.onRowDataChange(data1);
                  }
                }}
              />
              <span style={{ padding: "7px 5px", float: "left" }}>Mins </span>
            </div>
            <br />
            <div style={{ clear: "both", margin: "2rem 0 -1rem -1rem" }}>
              <span style={{ textAlign: "center" }}>Or</span>
            </div>
            <br />
            <TextField
              onKeyDown={onKeyDownPreventEnterClick}
              ref={myRef}
              style={{ width: "70px", float: "left",textAlign:'inherit', marginLeft: ".05rem" }}
              inputProps={{ maxlength: 15, minlegth: 1 }}
              value={getDecimalTime(props5.rowData.hearingDuration ? props5.rowData.hearingDuration : 0)}
              type="text"
              pattern="/^\d{1,}(\.\d{0,4})?$/"
              onInput={(e) => {
                console.log("text");
              }}
              onChange={(e) => {
                console.log("on change on dec hearing time", e.target.value);
                const start = e.target.selectionStart;
                let val = e.target.value;
                // val = val.replace(/([^0-9.]+)/, "");
                // val = val.replace(/^(0|\.)/, "");
                const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
                const value = match[1] + match[2];
                e.target.value = parseFloat((e.target.value))<=24?  value : 0.00;
                //this.setState({ input: value });
                props5.onChange(e.target.value);
                let data = { ...props5.rowData };
                data.hearingDuration = e.target.value * 60;
                data.startTime = null;
                data.endTime = null;
                props5.onRowDataChange(data);
                if (val.length > 0  ) {
                  e.target.value = parseFloat((val))<=24?  Number(value).toFixed(2) : 0.00;
                  e.target.setSelectionRange(start, start);
                  props5.onChange(Number(parseFloat((val))<=24? value:0.00).toFixed(2));
                  let data = { ...props5.rowData };
                  data.hearingDuration = Number(parseFloat((val))<=24? value :0.00).toFixed(2) * 60;
                  data.startTime = null;
                  data.endTime = null;
                  props5.onRowDataChange(data);
                }
              }}
            />{" "}
            <span style={{ padding: "7px 5px", float: "left" }}>
              Hrs.(Decimal)
            </span>
            </div>
            {/* {props5.rowData.hearingDuration === "" ||
              props5.rowData.hearingDuration === null ||
              props5.rowData.hearingDuration === undefined ? (
              <span style={{color:"#f00"}} className="custom--required">
               
                Required
              
              </span>
            ) : (
              <span></span>
            )} */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setIsDisable(
      timesheetDetails.status === "Accepted" ||
      timesheetDetails.status === "Sent for Review" 
    );
  }, []);

  const reviewNoteshandler = (event) => {
    settimesheetDetails({
      ...timesheetDetails,
      reviewNotes: event.target.value,
    });
    setreviewNotes(false);
    if (reviewStatus === "Clarification Required") {
      if (
        (event.target.value === "" ||
        event.target.value === null)
      ) {
        setreviewNotes(true);
        return;
      }
    }
    
  };
  const additionalNoteshandler = (event) => {
    settimesheetDetails({
      ...timesheetDetails,
      additionalNotes: event.target.value,
    });

  };

  const onRowAddCancelled =(rowData)=>{
    setisrowCancel(true);
    var element = document.getElementById("submitReviewTimesheet");
        if(element!=undefined)
        {
          element.classList.remove('pmaButtonDisabled')
          element.disabled=false;
         
        }
    console.log("onRowAddCancelled",rowData)
  }

  const onRowUpdateCancelled=(rowData)=>{
    var element = document.getElementById("submitReviewTimesheet");
        if(element!=undefined)
        {
          element.classList.remove('pmaButtonDisabled')
          element.disabled=false;
         
        }
console.log("onRowUpdateCancelled",rowData)
  }

  const DiscriptionRequired = (lineItem, isLineItem = false) => {
    if (lineItem && lineItem!=="^") {
      let tempLineItem = JSON.parse(JSON.stringify(lineItem))
      if (isLineItem && tempLineItem) {
        if(tempLineItem && tempLineItem.split("^")[0]===''){
          return true;
        }
        return tempLineItem && tempLineItem.split("^")[0] === "Other" && tempLineItem.split("^")[1] === "" ? true : false;
      }
      else {
        return tempLineItem && tempLineItem.split("^")[0] === "Other" ? true : false;
      }
    } else {
      if(lineItem === "^"){
        return true;
      }
      return false;
    }
  }

  const sendEmailNotificationForReview = (event, status) => {
    
    if (reviewStatus !== "Accepted") {
      let targetEmail =
        status === "Sent for Review"
          ? isMask
          ? mailMask:mailTimesheet
          : isMask
            ? mailMask
            : props.timesheetDetails.hearingOfficerEmail;
let subject=            status === "Sent for Review"
            ? COMMERCIAL_EMAIL_SUBJECT
            : COMMERCIAL_REVIEW_EMAIL_SUBJECT;
subject=subject.replace("${caseReference}",props.caseDetails.caseReference);
      const emailBody = {
        listEmailReceivers: [
          {
            receiverName: targetEmail,
            receiverEmail: targetEmail,
          },
        ],
        emailSubject:subject,
          
        emailTemplate:
          status === "Sent for Review"
            ? `<!DOCTYPE html><html><body><table style="width:40%;border:1px solid gray;text-align:center;margin:auto;">
                                <tr><td style="border:1px solid gray;font-size:26px;padding: 25px;">
                                <img src="${BASE_URL}${namlogo}" width="310px"><br>NAM Neutrals Portal Communication</td></tr>
                                <tr>    <td style="border:1px solid gray;font-size:18px;padding: 25px;background:#f2f2f2;text-align:left;">
                                ${props.timesheetDetails.hearingOfficerName} has submitted the ${props.timesheetDetails.timesheetName} timesheet for review. Please <a href='${BASE_URL}'>sign in</a> to the Neutrals Portal to review the submission.
                                </br><br><br><br>
                                <p style="text-align:left;">Thank you!<br>NAM Neutral Portal Customer Service<br/>+1 (800) 358-2550<br> </td></p>  </tr></table></body></html>`
            : `<!DOCTYPE html><html><body><table style="width:40%;border:1px solid gray;text-align:center;margin:auto;">
                                <tr><td style="border:1px solid gray;font-size:26px;padding: 25px;">
                                <img src="${BASE_URL}${namlogo}" width="310px"><br>NAM Neutrals Portal Communication</td></tr>
                                <tr>    <td style="border:1px solid gray;font-size:18px;padding: 25px;background:#f2f2f2;text-align:left;">${props.timesheetDetails.hearingOfficerName !==
              null &&
              props.timesheetDetails.hearingOfficerName !==
              undefined &&
              props.timesheetDetails.hearingOfficerName !==
              ""
              ? props.timesheetDetails.hearingOfficerName.split(
                " "
              )[0]
              : ""
            },
                                <br><br> 
                                The timesheet for ${props.timesheetDetails.timesheetName
            } has been reviewed by NAM and requires clarification. Please <a href='${BASE_URL}'>sign in</a> to Neutrals Portal to review.
                                </br><br><br><br>
                                <p style="text-align:left;">Thank you!<br>NAM Neutral Portal Customer Service<br/>+1 (800) 358-2550<br> </td></p>  </tr></table></body></html>`,

        emailBodyVariables: {
          additionalProp1: "",
          additionalProp2: "",
          additionalProp3: "",
        },
        filename: "",
        cc: "",
        bcc: "",
      };

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .post(CUSTOM_EMAIL_NOTIFICATION, emailBody, config)
        .then((_res) => {
         setTimeout(() => {
          setIsLoaded(false);
         }, 30);
        })
        .catch((_error) => {
          setTimeout(() => {
            setIsLoaded(false);
           }, 30);
        });
    }
  };

  const onChangeValue = (e) => {
    setReviewStatus(e.target.value);
    setreviewNotes(false);
    setreviewvalidation(false);
    if (e.target.value === "Clarification Required") {
      if (
        timesheetDetails &&
        (timesheetDetails.reviewNotes === "" ||
          timesheetDetails.reviewNotes === null)
      ) {
        setreviewNotes(true);
      }}
  };

  const focusOutAddEntry=(event)=>{
    event.currentTarget.blur();
    setAvoidDuplicates(true)
  }

  const handleAddContainer = (newData) => {
    if (isrowCancel) {
      return;
    }
    if (!avoidDuplicates) {
      if(!addZeroHourTimeSheet)
        return;      
    }
    if ( newData.lineItem ===
      "Other, Zero hour timesheet" || (newData.hearingDuration && newData.lineItem && newData.entryDate)) {
        setisrowCancel(false);
      seterror(false);
      const newfield = {
        index: timesheetArr.length + 1,
        id: "",
        hearingDuration: newData.hearingDuration,
        startTime: newData.startTime,
        endTime: newData.endTime,
        netTime: "0",
        hearingCode: "",
        lineItem: newData.lineItem,
        entryDate: newData.entryDate,
        status: "",
        action: "",
        timesheetMonth: "",
        notes: "",
      };
      let time = timesheetDetails.commercialtimesheetDetails;
      if (time === null || time === undefined) {
        time = [];
      }
      time.push(newfield);
      settimesheetArr(time);
      const timesheetDetail = timesheetDetails;
      timesheetDetail.commercialtimesheetDetails = time;
      settimesheetDetails({
        ...timesheetDetails,
        commercialtimesheetDetails: time,
      });

      saveTimesheet();
      props.onclick(timeSheetIndex, timesheetDetail);
      var element = document.getElementById("submitReviewTimesheet");
      if (element != undefined) {
        element.classList.remove('pmaButtonDisabled')
        element.disabled = false;
      }
    }
    else {
      return;
    }
  };
  const updatedisplayAlert = (e, type,index) => {
    if (type === "send") {
      setTimesheetIndex(index);
      setIsLoaded(true);
      sendforReview("",index);
      sendEmailNotificationForReview("", "Sent for Review");
    }
    setdisplayAlert(e);
  };
  const handlesendforReviewClick = (event) => {
    if (
      timesheetDetails.commercialtimesheetDetails !== null &&
      timesheetDetails.commercialtimesheetDetails !== undefined &&
      (timesheetDetails.commercialtimesheetDetails.length === 0 ||
        !timesheetDetails.commercialtimesheetDetails.every(
          (x) =>
            x.hearingDuration !== null &&
            x.hearingDuration !== "" &&
            x.hearingDuration !== undefined &&
            x.lineItem !== null &&
            x.lineItem !== "" &&
            x.lineItem !== undefined &&
            x.entryDate !== null &&
            x.entryDate !== "" &&
            x.entryDate !== undefined
        ))
    ) {
      seterror(true);
      seterrorMessage(
        "For one or more timesheet entries one of the required fields is not updated."
      );
      return;
    } else {
      setdisplayAlert(true);
     // setreviewData(event);
    }
  };
  const sendforReview = (event,index) => {
    const caseData = props.initalCasesData[0];
   
    caseData.commercialTimesheet[index] = timesheetDetails;
    caseData.commercialTimesheet[index].status = "Sent for Review";
    const timesheetDetail = timesheetDetails;
    timesheetDetail.status = "Sent for Review";
    
        
    settimesheetDetails(timesheetDetail);
    props.onclick(timeSheetIndex, timesheetDetails); //setisCancel(false);
    let request = {
      id: "",
      monthlyBillingType: caseData.monthlyBillingType,
      caseName: caseData.caseName,
      caseType: caseData.caseType,
      caseStatus: caseData.caseStatus,
      hearingDate: props.caseDetails.hearingDate,
      hearingDateISO: props.caseDetails.hearingDateISO,
      hearingOfficerCode: caseData.hearingOfficerCode,
      hearingOfficerName: caseData.hearingOfficerName,
      hearingOfficerEmail: caseData.hearingOfficerEmail,
      caseMethod: caseData.caseMethod,
      hearingCode: props.caseDetails.hearingCode,
      caseType2: caseData.caseType2,
      pendingAt: "review",
      caseCode: caseData.caseCode.toString(),
      isDeleted: false,
      timesheetStatus: "Sent for Review",
      iCaseReference: `${props.caseDetails.caseReference}`,
      caseReference: props.caseDetails.caseReference,
      hearingStatus: props.caseDetails.hearingStatus
    };
    
    var element = document.getElementById("submitReviewTimesheet");
    if(element!=undefined)
    {
      element.classList.remove('pmaButtonDisabled')
      element.disabled=false;
     
    }
    saveTimesheet();
    const url1 = `${INSERT_PENDING_TIMESHEET_DETAILS}`;
    axios
      .post(url1, request, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
                
          setisCancel(false);
          window.location.reload();
        }
      });
  };
  const submitReview = (event) => {
    if (reviewStatus === "Accepted") {
      const htmltopdf = document.getElementById(
        "commercialTimesheetTemplate"
      ).innerHTML;
      const htmltopdfurl = `${HTML_TO_PDF}`;
      const last_name = `${props.timesheetDetails.hearingOfficerName !== null
        ? props.timesheetDetails.hearingOfficerName.split(" ")[1]
        : ""
        }`;

      const body1 = {
        filename: `TimeSheet${last_name}_01_${props.timesheetDetails.timesheetName}`,
        htmlInput: htmltopdf,
        casename: `${props.caseDetails["caseName"]}`,
      };

      axios
        .post(htmltopdfurl, body1, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          if (res.status === 200) {
            const fileObj = dataURLtoFile(
              `data:application/pdf;base64,${res.data}`,
              `TimeSheet_${last_name}_01_${props.timesheetDetails.timesheetName}.pdf`
            );
            //// ===========Logic App End Point call...
            const date = new Date(Date.now());
            const currentDateMonth = date.toLocaleString('en-US', {month: 'short'}); 
            const currentDateYear = new Date().getFullYear();
            const request = {
              foldername:`${props.caseDetails["caseReference"]}`,
              filename:`TIMESHEET_${currentDateMonth.toUpperCase()}_${currentDateYear}`,
              action:"create",
              data: `${res.data}`,
            };

            axios
            .post(CMSSYNC_LOGICAPP, request, {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((resp) => {
              console.log("TimeSheet_CMSSYNC_LOGICAPP",resp)
            });

            //===========End ==============

            const url = `${INSERT_CASE_FILE}?caseReference=${props.caseDetails["caseReference"]
              }&filePath=${"case"}`;
            const formData = new FormData();
            formData.append("file", fileObj);
            formData.append(
              "fileName",
              `TimeSheet_${last_name}_01_${props.timesheetDetails.timesheetName}.pdf`
            );
            const config = {
              headers: {
                "content-type": "multipart/form-data",
                "Access-Control-Allow-Origin":"*",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            };

            axios.post(url, formData, config).then((response2) => {
              if (response2.status === 200) {
                reviewDecision(event, response2.data.downloadUrl);
                axios
                .post(
                  `${UPDATE_PENDINGTASKS}?pendingAt=timesheet&caseCode=${props.caseDetails["caseCode"]}&hearingCode=${props.caseDetails["hearingCode"]}&isCommercialCase=${true}`,
                  {},
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                .then((res_PendingTask) => {
                  if (res_PendingTask.status === 200) {
                   // setisCancel(false);
               
                //Call Azure function ..UPDATE_CMSTIMESHEET
                const body = {
                  caseCode: parseInt(props.caseDetails["caseCode"]),
                  hearingCode: 0,
                  updatedBy: parseInt(`${decryptItem("userid")}`),
                  updateDate: new Date(),
                  timesheetStart: props.caseDetails.hearingStartTime,
                  timesheetEnd: props.caseDetails.hearingEndTime,
                  localNotes: `${getTotalTime()} ${props.timesheetDetails.hearingOfficerName
                    } Time for ${props.timesheetDetails.timesheetName
                    } Approved By:${localStorage.getItem(
                      "hearingOfficerName"
                    )} Date:${new Date().toLocaleString("en-US")}`,
                  totalTime: sumTotalTime() / 60,
                  hearingOfficerCode: props.timesheetDetails.hearingOfficerCode,
                  firmCode: props.caseDetails.firmCode,
                  hearingStatus: props.caseDetails.hearingStatus
                };
                const config1 = {
                  headers: {
                    "content-type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                };
                if (isSyncBack) {
                  axios
                    .post(UPDATE_CMSTIMESHEET, body, config1)
                    .then((res2) => {
                      if (res2.status === 200) {
                        const hearingDetails= JSON.parse(decryptItem("hearingDetails"));
                        hearingDetails.pendingAt='timesheets';
                        encryptItem("hearingDetails",JSON.stringify(hearingDetails));
                        window.location.reload();
                      }
                    })
                    .catch((error1) => {
                      setIsLoaded(false);
                    });
                }   }
              });
              }
            });
          }
        });
    } else {
      reviewDecision(event);
      setIsLoaded(false);
    }
  };

  const processCTDetails = (cdtData) => {
    let saveCTDetailsTemp = []
    cdtData.commercialtimesheetDetails.forEach(savecTdetails => {
      savecTdetails.endTime = savecTdetails.endTime? Utility.convertTimeZoneToUTC(savecTdetails.endTime) : savecTdetails.endTime;
      savecTdetails.startTime =savecTdetails.startTime? Utility.convertTimeZoneToUTC(savecTdetails.startTime) : savecTdetails.startTime;
      savecTdetails.entryDate = Utility.convertTimeZoneToUTC(savecTdetails.entryDate);
      saveCTDetailsTemp.push(savecTdetails);
    });
    return saveCTDetailsTemp;
  }

  const reviewDecision = (event, fileUrl) => {
    setreviewNotes(false);
    setreviewvalidation(false);
    if (reviewStatus === "") {
      setreviewvalidation(true);
      return;
    } else {
      if (reviewStatus === "Clarification Required") {
        if (
          timesheetDetails &&
          (timesheetDetails.reviewNotes === "" ||
            timesheetDetails.reviewNotes === null)
        ) {
          setreviewNotes(true);
          return;
        }
      }
    }
    const timesheetDetail = timesheetDetails;
    timesheetDetail.status = reviewStatus;
    timesheetDetail.fileName = fileUrl;
    settimesheetDetails(timesheetDetail);
    props.onclick(timeSheetIndex, timesheetDetail);
    const body = props.initalCasesData[0];
    body.commercialTimesheet[timeSheetIndex] = timesheetDetails;
    body.commercialTimesheet[timeSheetIndex].commercialtimesheetDetails=processCTDetails(body.commercialTimesheet[timeSheetIndex]);

    const url1 = `${ADD_TIMESHEET_DETAILS}`;
    axios
      .post(url1, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          sendEmailNotificationForReview(event, "");
          axios
            .post(
              `${UPDATE_PENDINGTASKS}?pendingAt=review&caseCode=${props.caseDetails["caseCode"]}&hearingCode=${props.caseDetails["hearingCode"]}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res_PendingTask) => {
              if (res_PendingTask.status === 200) {
                setisCancel(false);
              }
            });
        }
      });
  };

  const saveTimesheet = () => {
    {
      if (!isrowCancel) {
        setAvoidDuplicates(false);
        addZeroHourTimeSheet = false;
        var element = document.getElementById("submitReviewTimesheet");
        if (element != undefined) {
          element.classList.remove('pmaButtonDisabled')
          element.disabled = false;

        }
        const body = props.initalCasesData[0];
        body.commercialTimesheet[timeSheetIndex] = timesheetDetails;
        body.commercialTimesheet[timeSheetIndex].commercialtimesheetDetails = processCTDetails(body.commercialTimesheet[timeSheetIndex]);
        const url1 = `${ADD_TIMESHEET_DETAILS}`;
        axios
          .post(url1, body, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.status === 200) {

              props.onclick(timeSheetIndex, timesheetDetails);
            }
          });
      }
    }
  };

  const sumTotalTime = () => {
    const sum =
      timesheetArr !== null &&
        timesheetArr !== undefined &&
        timesheetArr.length > 0
        ? timesheetArr
          .map((item) =>
            item.hearingDuration !== null &&
              item.hearingDuration !== undefined &&
              item.hearingDuration !== ""
              ? item.hearingDuration
              : 0
          )
          .reduce((prev, next) => prev + next)
        : 0;
    return sum;
  };
  const zeroHourTimesheet = () => {
    setisZeroHour(!isZeroHour);
    addZeroHourTimeSheet=true;
    if (!isZeroHour && timesheetArr.length === 0) {

      const newData = {
        startTime: null,
        endTime: null,
        entryDate: new Date(parseInt(monthNyear[1]), Utility.monthNames[monthNyear[0].trim().toLowerCase()], 1),
        //new Date(new Date().getFullYear(), new Date().getMonth(), 1), //new Date(),
        lineItem: "Other, Zero hour timesheet",
        hearingDuration: 0,
      }
      handleAddContainer(newData);
      var element = document.getElementById("submitReviewTimesheet");
      if(element!=undefined)
      {
        element.classList.remove('pmaButtonDisabled')
        element.disabled=false;
       
      }
      saveTimesheet();
    }
  }
  const getTotalTime = () => {
    const sum =
      timesheetArr !== null &&
        timesheetArr !== undefined &&
        timesheetArr.length > 0
        ? timesheetArr
          .map((item) =>
            item.hearingDuration !== null &&
              item.hearingDuration !== undefined &&
              item.hearingDuration !== ""
              ? item.hearingDuration
              : 0
          )
          .reduce((prev, next) => prev + next)
        : 0;
    return Utility.toHoursAndMinutes(sum);
  };

  const rowUpdate = (newData, oldData) => {
    if (newData.lineItem === "" || newData.lineItem === null) {
      seterrorMessage("Line Item is required.");
      seterror(true);
      return false;
    } else if (
      newData.hearingDuration === "" ||
      newData.hearingDuration === null ||
      newData.hearingDuration === 0
    ) {
      seterror(true);
      seterrorMessage(newData.lineItem=== "Other, Zero hour timesheet"?"": "Hearing duration is required.");
      return false;
    } else {
      newData.lineItem = newData.lineItem.replace("^", ", ");
      const time = timesheetArr;
      if (newData.entryDate !== null && newData.entryDate !== "") {
        newData.entryDate = new Date(newData.entryDate);
      }
      const index = time.indexOf(oldData);
      time[index] = newData;
      settimesheetArr(time);
      const timesheetDetail = timesheetDetails;

      timesheetDetail.commercialtimesheetDetails = time;
      settimesheetDetails({
        ...timesheetDetails,
        commercialtimesheetDetails: time,
      });
      props.onclick(timeSheetIndex, timesheetDetail);
      var element = document.getElementById("submitReviewTimesheet");
      if(element!=undefined)
      {
        element.classList.remove('pmaButtonDisabled')
        element.disabled=false;
       
      }
      saveTimesheet();
    }
  };
  const rowDelete = (oldData) => {
    if (oldData.tableData.editing == "delete") {
      const time = timesheetArr.filter((item, i) => item !== oldData);
      settimesheetArr(time);
      const timesheetDetail = timesheetDetails;
      timesheetDetail.commercialtimesheetDetails = time;
      settimesheetDetails({
        ...timesheetDetails,
        commercialtimesheetDetails: time,
      });
      props.onclick(timeSheetIndex, timesheetDetail);
      var element = document.getElementById("submitReviewTimesheet");
      if (element != undefined) {
        element.classList.remove('pmaButtonDisabled')
        element.disabled = false;

      }
      saveTimesheet();
      if (oldData.lineItem === "Other, Zero hour timesheet") {
        setisZeroHour(!isZeroHour);
      }
    }
  };
  const onChangePage = (page) => {
     console.log("onChangePage")
  };

  const onChangeRowsPerPage = (pageSize) => {
    console.log("onChangeRowsPerPage",)
  };
  

  return isCancel ? (
    <>
      <>
        <LoadingOverlay
          active={isLoaded}
          spinner
          text="Please wait working on your request..."
        >
          <div
            key={props.timesheetDetails.index}
            className="row"
            style={{ marginTop: "1.5rem" }}
          >
            <div style={{ textAlign: "left", marginBottom: "10px" }}>
              <p className="font-weight-bold" style={{ marginBottom: "0" }}>
                Please fill in the below details to submit your timesheet for{" "}
                {props.timesheetDetails.timesheetName}
              </p>
              <br />
              Please indicate the time spent on this case during the month.
              Fill out as applicable:
              <br />
              For each entry below, please indicate one of the following:
              In-Person Hearing / Phone-Video Conference / Document Review /
              Email / Paper Arbitration / Other (provide a brief description).

            </div>

          </div>
          {isdisplayAlert === true && (
            <ReviewTimesheetDialog
              isdisplayAlert={isdisplayAlert}
              updatedisplayAlert={updatedisplayAlert}
              index={timeSheetIndex}
            ></ReviewTimesheetDialog>
          )}
          <Card className="timesheetCard">
            <Card.Body>
              <div className="row">
                {/* {props.notsubmitted && props.notsubmittedTimesheet !== props.timesheetDetails.timesheetName && <span className="action" style={{ display: "flex", alignItems: "center" }}>
                                        <i
                                            style={{ color: "darksalmon" }}
                                            class="fa fa-warning"
                                        ></i>Please submit older timesheets before this.</span>
                                    } */}
                {timesheetDetails.reviewNotes !== null &&
                  timesheetDetails.reviewNotes !== undefined &&
                  timesheetDetails.reviewNotes !== "" && (
                    <div className="row">
                      <div
                        className="col-12"
                        style={{ textAlign: "left" }}
                      >
                        <span style={{ fontWeight: "bold" }}>Review Notes:</span>  
                        <span>&nbsp;{timesheetDetails.reviewNotes}</span>
                      </div>

                    </div>
                  )}

                {error && (
                  <div className="row" style={{ textAlign: 'left', }}>
                    <span style={{ color: "red" }}>{errorMessage}</span>
                  </div>
                )}
                {
                  (
                    <div className="col-md-12 col-12">
                      <div className="timesheet-datagridentries datagridentries dataTable">
                        {timesheetArr && timesheetArr.length === 0 && (
                          <div className="row">

                            <div style={{ display: "flex" }}>
                              <input
                                type="checkbox"
                                style={{ marginRight: "5px" }}
                                onChange={zeroHourTimesheet}
                                disabled={isDisabled || (timesheetArr.length > 1 ||
                                  timesheetArr.length === 1 && timesheetArr[0].lineItem !== "Other, Zero hour timesheet")}
                              />
                              Add a zero hour timesheet&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  All times in {Utility.userSpecificTimeZone()}
                            </div>
                          </div>)}
                        <MatDatagrid
                          rows={
                            timesheetArr
                              ? 
                              timesheetArr.sort(
                                (a, b) => {
                                  if (new Date(a.entryDate) < new Date(b.entryDate)) return -1;
                                  if (new Date(a.entryDate) > new Date(b.entryDate)) return 1;

                                  if (new Date(a.startTime) < new Date(b.startTime)) return -1;
                                  if (new Date(a.startTime) > new Date(b.startTime)) return 1;

                                  if (a.hearingDuration < b.hearingDuration) return -1;
                                  if (a.hearingDuration > b.hearingDuration) return 1;
                                  return 0;

                                }
                              )
                              
                              

                              : []
                          }
                          columns={columns}
                          grouping={false}
                          title={" "}
                          addRow={handleAddContainer}
                          addRowPosition={"first"}
                          editable={{
                            editable: !isDisabled,
                            showEdit: !(isDisabled ||
                              (Utility.IsHearingOfficer() && timesheetArr.length === 1 && timesheetArr[0].lineItem === "Other, Zero hour timesheet")),
                            showDelete: !isDisabled,
                            showAdd: !isDisabled || (timesheetArr !== undefined && timesheetArr !== null && timesheetArr.length === 1 && timesheetArr[0].lineItem !== "Other, Zero hour timesheet"),
                          }}
                          rowAdd={handleAddContainer}
                          rowUpdate={rowUpdate}
                          rowDelete={rowDelete}
                          filtering={false}
                          isCustomHeader={true}
                          selection={false}
                          actionsColumnIndex={-1}
                          draggable={false}
                          paging={true}
                          pageSize={5}
                          showFirstLastPageButtons={false}
                          pageSizeOptions={[5]}
                          paginationType={"stepped"}
                          customPagination={{
                            isDisplay: false,
                            totalCount: totalRecords,
                          }}
                          customIcon={{
                            Add: () => (
                              <div className="row">
                                <div
                                  id={"add-entry-timesheet-commercial"}
                                  className="col-12"
                                  style={{
                                    textAlign: "left",
                                    borderTop: "0px !important",
                                    marginTop: "-2.5rem",
                                  }}
                                >
                               
                                  <button
                                    className="timesheetButton btn-primary"
                                    name="addtimesheet"
                                    disabled={isDisabled || (timesheetArr.length === 1 && timesheetArr[0].lineItem === "Other, Zero hour timesheet")}
                                    hidden={isDisabled || (timesheetArr.length === 1 && timesheetArr[0].lineItem === "Other, Zero hour timesheet")}
                                    title=""
                                    onClick={focusOutAddEntry}
                                  >
                                    Add entry
                                  </button>
                                </div>
                              </div>
                            ),
                          }}
                          onChangePage={onChangePage}
                          onChangeRowsPerPage={onChangeRowsPerPage}
                          onRowAddCancelled={onRowAddCancelled}
                          onRowUpdateCancelled={onRowUpdateCancelled}
                        />
                      </div>
                    </div>
                  )}
              </div>
              <div className="row">
                <div style={{ textAlign: "right", paddingRight: "1rem" }}>
                  <span>
                    <b>Total time spent: </b>{" "}
                  </span>
                  <span>{getTotalTime()}</span>
                </div>
              </div>
              {/* {isFormSubmit && !isDisabled && (
                                    <div className="row">

                                        <div className="col-md-3 col-12" style={{
                                            textAlign: "left",
                                            borderTop: "0px !important",
                                            marginTop: "2.5rem",
                                            marginBottom: ".5rem",
                                        }}>
                                            <button className="timesheetButton btn-primary" name="addtimesheet"
                                                disabled={isDisabled}
                                                onClick={handleAddContainer} >
                                                Add entry
                                            </button>
                                        </div>

                                    </div>
                                )} */}
              <div className="row">
                <label
                  htmlFor="staticEmail"
                  className="col-form-label float-start text-start"
                  style={{ fontWeight: "bold" }}
                >
                  Additional Notes
                </label>
                <div className="" style={{textAlign: "left"}}>
                  <F1GrammarCheckerTextArea
                  key="additionanotestimesheetkey"
                  type="textarea"
                  rows="6"
                  id="additionanotestimesheetid"
                  num_retries={3}
                  buttonBackgroundColor="#a55cec"
                  buttonText={null}
                  buttonTextColor='white'
                  grammarChecking={true}
                  grammarProvider='AzureOpenAI'
                  grammarProviderURI='https://spellcheckgpt4.openai.azure.com/'
                  grammarProviderEndpointName='gpt-35-turbo-16k'
                  grammarProviderAPIVersion='2024-05-01-preview'
                  azureApiKey="425311175a3c4ca49545a7ad1cd1e5df"
                  customEditorClassName="additionanotestimesheetclass"
                  setTextData={setTextData}
                  textData = {textData1}
                  />
                    {/* <textarea
                    onBlur={saveTimesheet}
                      style={{ width: "100%" }}
                      readOnly={isDisabled}
                      name="additionalNotes"
                      value={timesheetDetails.additionalNotes}
                      spellCheck={true}
                      onChange={additionalNoteshandler}
                      required={true}
                    /> */}
                 
                </div>
              </div>
              {!isDisabled && (
                <>
                  {error && (
                    <div className="row" style={{ textAlign: 'left', }}>
                      <span style={{ color: "red" }}>{errorMessage}</span>
                    </div>
                  )}
                  <div className="row">

                    <div
                      className="col-md-4 col-12"
                      style={{
                        textAlign: "left",
                        borderTop: "0px !important",
                        marginTop: ".5rem",
                        marginBottom: ".5rem",
                      }}
                    >

                      <button
                        className="timesheetButton btn-primary"
                        name="submitTimesheet"
                        disabled={isDisabled}
                        onClick={(event) => {
                          handlesendforReviewClick(event);
                        }}
                        id="submitReviewTimesheet"
                      >
                        Send for NAM Review
                      </button>
                      {/* <button className="timesheetButton btn-primary" name="cancelTimesheet"
                                                disabled={isDisabled} onClick={(event) => setisCancel(false)} >
                                                Cancel
                                            </button> */}
                    </div>
                  </div>
                </>
              )}

              <Card.Text></Card.Text>
            </Card.Body>
          </Card>
          {!Utility.IsHearingOfficer() &&
            timesheetDetails.status === "Sent for Review" && (
              <>
                <Card className="reviewCard">
                  {" "}
                  <Card.Body>
                    <>
                      <div>
                        <div className="mb-1 row">
                          <label className="col-md-3 col-12 col-form-label float-start text-start">
                            Review
                          </label>
                          <div className="col-md-4 col-12 float-start text-start">
                            <div onChange={onChangeValue}>
                              <input
                              defaultChecked
                                type="radio"
                                value="Accepted"
                                name="status"
                                checked={reviewStatus === "Accepted"}
                              />{" "}
                              Accepted
                              <input
                                type="radio"
                                value="Clarification Required"
                                name="status"
                                checked={
                                  reviewStatus === "Clarification Required"
                                }
                                style={{ marginLeft: "20px" }}
                              />{" "}
                              Clarification Required
                            </div>
                            {reviewValidation ? (
                              <span style={{ color: "red" }}>
                                Please select review type
                              </span>
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </div>
                        <div className="mb-1 row">
                          <label className="col-md-3 col-12 col-form-label float-start text-start">
                            Review Notes
                          </label>
                          <div className="col-md-4 col-12 float-start" style={{textAlign: "left"}}>
                          <F1GrammarCheckerTextArea
                            key="additionanotestimesheetkey"
                            type="textarea"
                            rows="6"
                            id="additionanotestimesheetid"
                            num_retries={3}
                            buttonBackgroundColor="#a55cec"
                            buttonText={null}
                            buttonTextColor='white'
                            grammarChecking={true}
                            grammarProvider='AzureOpenAI'
                            grammarProviderURI='https://spellcheckgpt4.openai.azure.com/'
                            grammarProviderEndpointName='gpt-35-turbo-16k'
                            grammarProviderAPIVersion='2024-05-01-preview'
                            azureApiKey="425311175a3c4ca49545a7ad1cd1e5df"
                            customEditorClassName="additionanotestimesheetclass"
                            setTextData={reviewSetTextData}
                            textData = {reviewTextData1}
                            />
                            {/* <input
                              type="text"
                              style={{ width: "100%" }}
                              name="rejectionDetails"
                              onChange={reviewNoteshandler}
                              value={timesheetDetails.reviewNotes}
                              spellCheck={true}
                            /> */}
                          </div>
                          {reviewNotes ? (
                            <div className="row">
                              <div
                                className="col-md-7 col-12"
                                style={{ color: "red" }}
                              >
                                Please add review notes
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </>

                    <div className="timesheet time">
                      <div className="text-start">
                        <Button
                          className="timesheetButton btn-primary"
                          disabled={timesheetDetails.status === "Accepted"}
                          onClick={(event) => {
                            setIsLoaded(true);
                            submitReview(event);
                          }}
                          id="submitReview"
                        >
                          Submit Review
                        </Button>
                        {/* <button className="timesheetButton btn-primary" name="cancelTimesheet"
                                                disabled={timesheetDetails.status === "Accepted"} onClick={(event) => setisCancel(false)} >
                                                Cancel
                                            </button> */}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </>
            )}
        </LoadingOverlay>
      </>
      <div id="commercialTimesheetTemplate" style={{ display: "none" }}>
        <CommercialTimesheetTemplate
          timeSheetDetails={props.timesheetDetails}
          caseDetails={props.caseDetails}
        />
      </div>
    </>
  ) : (
    <>
      <div></div>
    </>
  );
};

export default CommercialTimesheet;
